.title-servicos {
    width: 100%;
    height: 20rem;
    background-image: linear-gradient(to right, #2b2b2b, #323232);
}

.title-servicos-h2-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 4rem;
    width: 100%;
    height: 100%;
    box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.5);
    animation: h2-icon 0.5s forwards ease-in-out;
    visibility: hidden;
}

@keyframes h2-icon {
    from {
        position: relative;
        left: -2rem;
        top: 0;
        opacity: 0;
    }
    to {
        position: relative;
        left: 0rem;
        top: 0;
        opacity: 1;
        visibility: visible;
    }
}

.title-servicos-h2-icon h2 {
    font-size: 1.5rem;
    font-weight: 600;
    letter-spacing: 1px;
    color: #fff;
    margin-left: 1rem;
}

.content-about-atmpv {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 4rem 0rem 4rem 0rem;
}

.content-atmpv {
    width: 65%;
}

.content-atmpv h4 {
    font-size: 1.375rem;
    font-weight: 400;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
    padding-bottom: 0.65rem;
    animation: h4-titles 0.5s forwards ease-in-out;
    visibility: hidden;
    animation-delay: 0.5s;
}

@keyframes h4-titles {
    from {
        position: relative;
        left: 0rem;
        top: 2rem;
        opacity: 0;
    }
    to {
        position: relative;
        left: 0;
        top: 0rem;
        opacity: 1;
        visibility: visible;
    }
}

.content-atmpv span {
    font-size: 1.125rem;
    font-weight: 300;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
}

.servicos-especializados {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-bottom: 2rem;
    text-align: center;
}

.services-spc {
    display: grid;
    grid-gap: 4rem;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    padding: 2rem 1rem 2rem 1rem;
}

.serv-indv:nth-child(1) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    animation: divs-icons 0.5s forwards ease-in-out;
    visibility: hidden;
    animation-delay: 0.8s;
}
.serv-indv:nth-child(2) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    animation: divs-icons 0.5s forwards ease-in-out;
    visibility: hidden;
    animation-delay: 1s;
}
.serv-indv:nth-child(3) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    animation: divs-icons 0.5s forwards ease-in-out;
    visibility: hidden;
    animation-delay: 1.2s;
}
.serv-indv:nth-child(4) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    animation: divs-icons 0.5s forwards ease-in-out;
    visibility: hidden;
    animation-delay: 1.4s;
}
.serv-indv:nth-child(5) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    animation: divs-icons 0.5s forwards ease-in-out;
    visibility: hidden;
    animation-delay: 1.6s;
}
.serv-indv:nth-child(6) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    animation: divs-icons 0.5s forwards ease-in-out;
    visibility: hidden;
    animation-delay: 1.8s;
}
.serv-indv:nth-child(7) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    animation: divs-icons 0.5s forwards ease-in-out;
    visibility: hidden;
    animation-delay: 2s;
}
.serv-indv:nth-child(8) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    animation: divs-icons 0.5s forwards ease-in-out;
    visibility: hidden;
    animation-delay: 2.2s;
}
.serv-indv:nth-child(9) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    animation: divs-icons 0.5s forwards ease-in-out;
    visibility: hidden;
    animation-delay: 2.4s;
}
.serv-indv:nth-child(10) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    animation: divs-icons 0.5s forwards ease-in-out;
    visibility: hidden;
    animation-delay: 2.6s;
}

#animate-delay{
    animation: divs-icons 0.5s forwards ease-in-out;
    visibility: hidden;
    animation-delay: 2.8s;
}

@keyframes divs-icons {
    from {
        position: relative;
        left: 0rem;
        top: 2rem;
        opacity: 0;
    }
    to {
        position: relative;
        left: 0;
        top: 0rem;
        opacity: 1;
        visibility: visible;
    }
}

.serv-indv span {
    font-size: 1.125rem;
    padding-top: 0.5rem;
    text-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
    width: 10rem;
}

#shadown-icon {
    filter: drop-shadow(2px 2px 1px rgba(0, 0, 0, .4));
}

.title-old-cars {
    width: 100%;
    height: 100%;
    text-align: center;
    background-color: #323232;
    padding: 3rem;
}

.title-old-cars h2 {
    font-size: 1.5rem;
    font-weight: 600;
    letter-spacing: 1px;
    color: #fff;
    text-transform: uppercase;
}

.old-cars {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-top: 2rem;
}

.content-old {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 62.5rem;
    height: 100%;
    box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.15);
}

.div-separation-old {
    border-radius: 3px;
    width: 50%;
    height: 100%;
}

.div-separation-old {
    display: flex;
    justify-content: center;
    width: 50%;
    height: 50%;
}

.div-separation-old img {
    width: 100%;
    width: 100%;
    object-fit: cover;
}

.div-separation-old p {
    font-size: 1.125rem;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
    font-weight: 200;
    width: 90%;
}

@media (max-width: 1441px) {
    .content-atmpv {
        width: 75%;
    }
}

@media (max-width: 1090px) {
    .content-atmpv {
        width: 75%;
    }
    .services-spc {
        display: grid;
        grid-gap: 4rem;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        padding: 2rem 1rem 2rem 1rem;
    }
}

@media (max-width: 1001px) {
    .content-old {
        width: 50rem;
    }
}

@media (max-width: 870px) {
    .services-spc {
        display: grid;
        grid-gap: 4rem;
        grid-template-columns: 1fr 1fr 1fr;
        padding: 2rem 1rem 2rem 1rem;
    }
}

@media (max-width: 1001px) {
    .content-old {
        width: 95%;
    }
}

@media (max-width: 715px) {
    .content-atmpv {
        width: 90%;
    }
    .title-servicos-h2-icon h2 {
        font-size: 1.375rem;
        margin-left: 0rem;
        text-align: center;
    }
    #none-mobile {
        display: none;
    }
}

@media (max-width: 640px) {
    .services-spc {
        display: grid;
        grid-gap: 2.5rem;
        grid-template-columns: 1fr 1fr;
        padding: 2rem 1rem 2rem 1rem;
    }
    .content-atmpv h4 {
        font-size: 1.25rem;
        font-weight: 400;
        text-shadow: 2px 2px 4px rgb(0 0 0 / 25%);
        padding-bottom: 0.65rem;
    }
    .content-old{
        width: 100%;
        flex-direction: column;
    }
    .div-separation-old {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;
        box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.15);
    }
    .div-separation-old p{
        padding: 2rem 0rem 2rem 0rem;
        font-weight: 300;
    }
    .old-cars {
        padding-top: 0rem;
    }
}

@media (max-width: 395px) {
    .services-spc {
        display: grid;
        grid-gap: 2.5rem;
        grid-template-columns: 1fr;
        padding: 2rem 1rem 2rem 1rem;
    }
    .content-atmpv {
        width: 95%;
    }
}