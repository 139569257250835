@font-face {
    font-family: "porscha3";
    src: local("porscha3"),
    url("../fonts/911porschav3.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "porscha3Bold";
    src: local("porscha3Bold"),
    url("../fonts/911porschav3bold.ttf") format("truetype");
    font-weight: bold;
}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
    
}

body{
    background-color: #FCFCFC;
}

.align-maps{
    width: 100%;
    height: 100%;
}