.background-blindagem {
    width: 100%;
    height: 25rem;
    background-image: url('../../assets/images/background_pages/background-blindagm.jpg');
    background-position: bottom;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.black-transparent-blindagem {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.65);
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
}

.background-blindagem h2 {
    font-size: 1.5rem;
    color: #fff;
    font-weight: 500;
    letter-spacing: 3px;
    text-transform: uppercase;
    padding-top: 4rem;
    padding-bottom: 0.5rem;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
    animation: blind-h2 0.5s forwards ease-in-out;
}

@keyframes blind-h2 {
    from {
        position: relative;
        left: 0;
        top: 2rem;
        opacity: 0.2;
    }
    to {
        position: relative;
        left: 0;
        top: 0rem;
        opacity: 1;
    }
}   

.background-blindagem p {
    font-size: 1.125rem;
    color: #fff;
    font-weight: 200;
    letter-spacing: 1px;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.15);
    animation: blindp 0.4s forwards ease-in-out;
    animation-delay: 0.5s;
    visibility: hidden;
}

@keyframes blindp {
    from {
        position: relative;
        left: 0;
        top: -0.5rem;
        opacity: 0.2;
    }
    to {
        position: relative;
        left: 0;
        top: 0rem;
        opacity: 1;
        visibility: visible;
    }
}   

.content-blindagem-page {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.about-blindagem {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-top: 5rem;
    padding-bottom: 5rem;
    width: 70%;
} 

.image-udura-garantia {
    display: flex;
    justify-content: center;
    width: 40%;
}

.texts-blindagem {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 60%;
    animation: about-blind 0.4s forwards ease-in-out;
    animation-delay: 1s;
    visibility: hidden;
}

@keyframes about-blind {
    from {
        position: relative;
        left: -3rem;
        top:  0rem;
        opacity: 0.2;
    }
    to {
        position: relative;
        left: 0rem;
        top: 0rem;
        opacity: 1;
        visibility: visible;
    }
} 

.texts-blindagem h4 {
    font-size: 1.25rem;
    font-weight: 500;
    text-shadow: 3px 3px 4px rgba(0, 0, 0, 0.2);
    text-transform: uppercase;
}

.texts-blindagem span {
    color: #433f43bb;
    font-size: 1.125rem;
    font-weight: 300;
    text-shadow: 3px 3px 4px rgba(0, 0, 0, 0.2);
    padding-top: 1rem;
}

.image-udura-garantia img {
    width: 20rem;
    height: 9.438rem;
    object-fit: contain;
    animation: img-blind 0.4s forwards ease-in-out;
    animation-delay: 1s;
    visibility: hidden;
}

@keyframes img-blind {
    from {
        position: relative;
        left: 0rem;
        top:  2rem;
        opacity: 0.2;
    }
    to {
        position: relative;
        left: 0rem;
        top: 0rem;
        opacity: 1;
        visibility: visible;
    }
} 

.benefits-udura {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 3rem;
    padding-bottom: 3rem;
    width: 100%;
    height: 100%;
    background-color: #323232;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.5);
}

/* .image-udura-logo{
    display: flex;
    justify-content: center;
    width: 40%;
}

.beneficios-udura {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 60%;
} */

.image-udura-logo img {
    width: 18rem;
    height: 8rem;
    object-fit: contain;
    animation: img-blind 0.4s forwards ease-in-out;
    animation-delay: 1.4s;
    visibility: hidden;
}

@keyframes img-blind {
    from {
        position: relative;
        left: 0rem;
        top:  2rem;
        opacity: 0.2;
    }
    to {
        position: relative;
        left: 0rem;
        top: 0rem;
        opacity: 1;
        visibility: visible;
    }
}
.width-background {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70%;
    height: 100%;
}

.beneficios-udura{
    animation: ul-blind 0.4s forwards ease-in-out;
    animation-delay: 1.4s;
    visibility: hidden;
}

@keyframes ul-blind {
    from {
        position: relative;
        left: -2rem;
        top:  0rem;
        opacity: 0.2;
    }
    to {
        position: relative;
        left: 0rem;
        top: 0rem;
        opacity: 1;
        visibility: visible;
    }
}

.beneficios-udura p {
    font-size: 1.125rem;
    font-weight: 400;
    color: #fff;
    padding-bottom: 1rem;
}

.beneficios-udura ul li {
    font-size: 1.125rem;
    font-weight: 300;
    color: rgba(255, 255, 255, 0.75);
    letter-spacing: 1px;
    margin-left: 1rem;
}

.car-udura {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 2rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
    background-color: #fff;
}

.car-udura p {
    font-size: 1.125rem;
    color: rgba(0, 0, 0, 0.65);
    text-align: center;
    width: 70%;
    padding-top: 2rem;
    font-weight: 200;
    text-shadow: 1px 1px 3px rgba(0, 3, 3, 0.25);
}

.blindagem-convencional {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 2rem;
    background-color: #f3f3f3;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.5);
}

.about-covencional {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 70%;
}

.about-covencional h4 {
    font-size: 1.25rem;
    font-weight: 500;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.35);
    padding-bottom: 1rem;
}

.about-covencional h5 {
    font-size: 1.125rem;
    font-weight: 400;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.35);
    padding-bottom: 0.25rem;
}

.about-covencional span {
    font-size: 1.125rem;
    font-weight: 300;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.35);
    padding-bottom: 1rem;
}

.vidros-glass {
    text-align: center;
    width: 100%;
    height: 100%;
    background-image: url('../../assets/images/car-glass.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    text-align: center;
}

.vidros-glass h2 {
    font-size: 1.375rem;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: porscha3bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
    padding-top: 4rem;
    padding-bottom: 2rem;
    width: 70%;
}

.vidros-glass span {
    font-size: 1.125rem;
    color: #fff;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
    padding-bottom: 0.95rem;
    font-weight: 200;
    width: 70%;
}

#padding-span-blind {
    padding-bottom: 4rem;
}

#padding-footer-blind {
    padding-top: 4rem;
}

@media (max-width: 1325px) {
    .about-blindagem {
        width: 85%;
    }
    .image-udura-garantia {
        justify-content: flex-end;
    }
    .width-background {
        width: 85%;
    }
    .car-udura p {
        width: 85%;
    }
    .vidros-glass h2 {
        width: 85%;
    }
    .about-covencional {
        width: 85%;
    }
    .vidros-glass span {
        width: 85%;
    }
    .car-udura img {
        width: 40rem;
        height: 20rem;
        object-fit: contain;
    }
    .image-udura-garantia img {
        width: 12rem;
        height: 8rem;
        object-fit: contain;
    }
    .image-udura-logo img {
        width: 12rem;
        height: 8rem;
        object-fit: contain;
    }
}

@media (max-width: 850px) {
    .about-blindagem {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-top: 3rem;
        padding-bottom: 3rem;
        width: 100%;
    }
    .image-udura-garantia {
        justify-content: center;
        padding-top: 3rem;
    }
    .image-udura-garantia img {
        width: 10rem;
        height: 10rem;
    }
    .texts-blindagem span {
        color: #433f43bb;
        font-size: 1.125rem;
        font-weight: 300;
        text-shadow: 3px 3px 4px rgb(0 0 0 / 20%);
        padding-top: 1rem;
    }
    .width-background {
        flex-direction: column-reverse;
    }
    .image-udura-logo {
        padding-top: 3rem;
    }
    .background-blindagem {
        text-align: center;
    }
}

@media (max-width: 645px) {
    .car-udura img {
        width: 30rem;
        height: 20rem;
        object-fit: contain;
    }
    .texts-blindagem {
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 85%;
    }
}

@media (max-width: 500px) {
    .car-udura img {
        width: 95%;
        height: 95%;
        object-fit: contain;
    }
    .image-udura-garantia img {
        width: 85%;
        height: 85%;
        object-fit: contain;
    }
    .image-udura-logo {
        display: flex;
        justify-content: center;
    }
    .image-udura-logo img {
        width: 60%;
        height: 60%;
        object-fit: contain;
    }
    .car-udura p {
        font-weight: 300;
    }
}

@media (max-width: 365px) {
    .texts-blindagem {
        justify-content: center;
        width: 95%;
    }
    .width-background {
        width: 95%;
        height: 100%;
    }
    .car-udura p {
        width: 95%;
    }
    .blindagem-convencional {
        padding: 0.15rem;
    }
    .about-covencional h4 {
        padding-top: 2rem;
    }
    #padding-bottom-span {
        padding-bottom: 2rem;
    }
    .vidros-glass span {
        width: 95%;
    }
}

/* Parei o responsivo em 983px */