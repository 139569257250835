.footer{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%
}

.content-footer{
    width: 100%;
    height: 100%;
}

.span-credits{
    text-align: center;
    width: 100%;
    height: 100%;
    padding: 0.75rem;
    background-color: #323232;
    box-shadow: 3px 3px 6px 4px rgba(0, 0, 0, 0.25);
}

.span-credits span{
    text-align: center;
    color: rgba(255, 255, 255, 0.5);
    font-size: 1.125rem;
    font-weight: 300;
}

.informations-footer{
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #424242;
    box-shadow: 3px 3px 6px 4px rgba(0, 0, 0, 0.25);
    padding: 2rem;
}

.division-footer{
    display: flex;
    flex-direction: column;
    margin-right: 2rem;
    margin-left: 2rem;
}

.division-footer h4{
    font-size: 0.875rem;
    font-weight: 700;
    color: #f3f3f3;
}

.cllnm-footer{
    display: flex;
    flex-direction: column;
    padding-top: 0.25rem;
}

.cllnm-footer span,
.cllnm-footer a{
    color: #fff;
    font-size: 0.875rem;
    text-decoration: none;
    transition: all 0.2s;
    cursor: pointer;
}

.cllnm-footer a:hover{
    color: #a0a0a0;
}

@media (max-width: 945px) {
    .informations-footer{
        display: grid;
        grid-gap: 15px;
        grid-template-columns: 1fr 1fr;
        grid-auto-flow: row;
    }
}

@media (max-width: 464px) {
    .span-credits span{
        font-size: 1rem;
    } 
}

@media (max-width: 365px) {
    .span-credits span{
        font-size: 0.875rem;
    } 
}

@media (max-width: 535px) {
    .informations-footer{
        display: grid;
        grid-gap: 25px;
        grid-template-columns: 1fr;
        grid-auto-flow: row;
    }
    .span-credits span {
        font-size: 1rem;
    }
}

@media (max-width: 366px) {
    .span-credits span {
        font-size: 0.875rem;
    }
}

