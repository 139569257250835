.maps {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 5rem;
}

.content-maps iframe {
    display: flex;
    justify-content: center;
    align-items: center;
}

#maps-size {
    width: 62rem;
    height: 25rem;
    border: none;
    border-radius: 3px;
    box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.11);
}

.icons-inform {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 62rem;
    padding-top: 2rem;
}

.icon-info {
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-info span {
    font-size: 1.125rem;
    font-weight: 300;
}

@media(max-width: 1000px) {
    #maps-size {
        width: 45rem;
        height: 20rem;
    }
    .icons-inform {
        width: 45rem;
    }
}

@media (max-width: 735px) {
    .maps {
        width: 100%;
        width: 100%;
        padding: 0rem;
    }
    .content-maps {
        width: 100%;
        padding-top: 5rem;
    }
    #maps-size {
        width: 100%;
        height: 20rem;
    }
    .icons-inform {
        width: 100%;
        padding-bottom: 2rem;
    }
}

@media (max-width: 641px) {
    .icons-inform {
        flex-direction: column;
        align-items: baseline;
    }
    .icon-info {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }
}

@media (max-width: 390px) {
    .icon-info span {
        font-size: 1rem;
        margin-left: 0.25rem;
    }
    .icon-info{
        margin-right: 1rem;
        margin-left: 0.5rem;
    }
}