.background-initial-sobre {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 45rem;
    background-image: linear-gradient(to right, #000, #2b2b2b);
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.5);

}

.content-sobre {
    display: flex;
    justify-content: center;
    width: 61rem;
    height: 27rem;
    border-radius: 3px;
    background-color: #fff;
    box-shadow: 2px 2px 3px rgba(255, 255, 255, 0.1);
}

.separation-sobre {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 50%;
    height: 100%;
}

.title-sobre {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 2rem;
    height: 20%;
}

.title-sobre-black {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 2rem;
    height: 20%;
    background-color: #323232;
}

.title-sobre {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 2rem;
    height: 20%;
}

.title-sobre h2 {
    font-size: 1.375rem;
    font-family: porscha3bold;
    letter-spacing: 2px;
    animation: animate-h2-sobre 0.5s forwards ease-in-out;
    animation-delay: 0.5s;
    visibility: hidden;
}

@keyframes animate-h2-sobre {
    from {
        position: relative;
        left: 0rem;
        top: 1rem;
        opacity: 0.2;
    }
    to {
        position: relative;
        left: 0rem;
        top: 0rem;
        opacity: 1;
        visibility: visible;
    }
}

#font-white {
    font-size: 1.375rem;
    font-family: porscha3bold;
    color: #ffff;
    letter-spacing: 2px;
}

.text-maps-sobre-letter {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 80%;
    padding: 0rem 1rem 1rem 1rem;
}

.text-maps-sobre-letter span {
    font-weight: 300;
    padding-bottom: 0.75rem;
}

.text-maps-sobre-letter span:nth-child(1) {
    animation: animate-span-sobre 0.5s forwards ease-in-out;
    animation-delay: 0.65s;
    visibility: hidden;
}

.text-maps-sobre-letter span:nth-child(2) {
    animation: animate-span-sobre 0.5s forwards ease-in-out;
    animation-delay: 0.95s;
    visibility: hidden;
}

.text-maps-sobre-letter span:nth-child(3) {
    animation: animate-span-sobre 0.5s forwards ease-in-out;
    animation-delay: 1.25s;
    visibility: hidden;
}

.text-maps-sobre-letter span:nth-child(4) {
    animation: animate-span-sobre 0.5s forwards ease-in-out;
    animation-delay: 1.5s;
    visibility: hidden;
}

.text-maps-sobre-letter span:nth-child(5) {
    animation: animate-span-sobre 0.5s forwards ease-in-out;
    animation-delay: 1.65s;
    visibility: hidden;
}

.text-maps-sobre-letter h4 {
    animation: animate-span-sobre 0.5s forwards ease-in-out;
    animation-delay: 1.65s;
    visibility: hidden;
}

@keyframes animate-span-sobre {
    from {
        position: relative;
        left: -1rem;
        top: 0rem;
        opacity: 0.2;
        transform: scale(0.95);
        -webkit-transform: scale(0.95);
    }
    to {
        position: relative;
        left: 0rem;
        top: 0rem;
        opacity: 1;
        visibility: visible;
        transform: scale(1);
        -webkit-transform: scale(1);
    }
}

.text-maps-sobre {
    width: 100%;
    height: 80%;
}

#maps-sobre {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 0px 0px 3px 0px;
    box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.3);
}

.certificados {
    width: 100%;
    height: 100%;
    padding: 5rem;
}

.certificados h1 {
    text-align: center;
    font-size: 1.5rem;
    font-family: porscha3;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    letter-spacing: 3px;
    text-transform: uppercase;
    padding-bottom: 5rem;
}

.all-certificate {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: 100%;
    /* display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-auto-flow: row; */
}

.certificate img {
    width: 16rem;
    height: 22rem;
    object-fit: contain;
    cursor: pointer;
    transition: all 0.35s ease-in-out;
    box-shadow: 3px 3px 6px 2px rgba(0, 0, 0, 0.1);
}

.certificate img:hover {
    opacity: 0.5;
}

.certificate p {
    text-align: center;
    padding: 1rem;
    background-color: #323232;
    color: #fff;
    font-size: 1.125rem;
    font-weight: 300;
    border-radius: 0px 0px 5px 5px;
    text-shadow: 2px 2px 3px rgba(255, 255, 255, 0.1);
    box-shadow: 3px 3px 6px 2px rgba(0, 0, 0, 0.1);
}

.anos-sobre{
    width: 100%;
    height: 100%;
    background-image: url('../../assets/images/image-sobre.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    background-position-y: 0rem;
}

.black-transparent-sobre {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.65);
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
}

.black-transparent-sobre p{
    text-align: center;
    font-family: porscha3bold;
    letter-spacing: 3px;
    text-transform: uppercase;
    font-size: 1.5rem;
    color: #fff;
    padding: 5rem;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}


.valores {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 5rem 5rem 5rem 5rem;
}

.valores h2 {
    font-size: 1.5rem;
    font-weight: 500;
    color: #323232;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
}

#icon-shadow {
    filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.25));
}

.content-valores {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-top: 5rem;
}

.division-valores:nth-child(1) {
    display: flex;
    align-items: center;
    flex-direction: column;
    animation: divs-icons 0.5s forwards ease-in-out;
    visibility: hidden;
    animation-delay: 1s;
}
.division-valores:nth-child(2) {
    display: flex;
    align-items: center;
    flex-direction: column;
    animation: divs-icons 0.5s forwards ease-in-out;
    visibility: hidden;
    animation-delay: 1.2s;
}
.division-valores:nth-child(3) {
    display: flex;
    align-items: center;
    flex-direction: column;
    animation: divs-icons 0.5s forwards ease-in-out;
    visibility: hidden;
    animation-delay: 1.4s;
}
.division-valores:nth-child(4) {
    display: flex;
    align-items: center;
    flex-direction: column;
    animation: divs-icons 0.5s forwards ease-in-out;
    visibility: hidden;
    animation-delay: 1.6s;
}

@keyframes divs-icons {
    from {
        position: relative;
        left: 0rem;
        top: 2rem;
        opacity: 0;
    }
    to {
        position: relative;
        left: 0;
        top: 0rem;
        opacity: 1;
        visibility: visible;
    }
}

.division-valores span {
    text-align: center;
    padding-top: 0.5rem;
    font-size: 1.125rem;
    font-weight: 300;
    text-transform: uppercase;
    color: #323232;
}

@media (max-width: 1240px) {
    .division-valores span {
        width: 15rem;
        width: 15rem;
    }
}

@media (max-width: 1215px) {
    .certificate img {
        width: 12rem;
        height: 18rem;
    }
    .certificados h1 {
        font-size: 1.375rem;
    }
    .certificate p {
        font-size: 1rem;
    }
}

@media (max-width: 992px) {
    .content-sobre {
        width: 95%;
        height: 70%;
    }
}

@media (max-width: 975px) {
    .all-certificate {
        display: grid;
        grid-gap: 20px;
        grid-template-columns: 1fr 1fr 1fr;
        grid-auto-flow: row;
    }
    .certificate p {
        width: 12rem;
        position: relative;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    .certificate img {
        position: relative;
        left: 50%;
        top: 0%;
        transform: translate(-50%, -0%);
    }
}

@media (max-width: 1240px) {
    .content-valores {
        display: grid;
        grid-gap: 30px;
        grid-template-columns: 1fr 1fr;
        grid-auto-flow: row;
    }
}

@media (max-width: 1050px) {
    .division-valores span {
        width: 15rem;
        font-size: 1rem;
    }
}

@media (max-width: 820px) {
    .background-initial-sobre {
        height: 60rem;
    }
    .content-sobre {
        flex-direction: column;
        width: 80%;
        height: 80%;
    }
    .content-sobre {
        position: relative;
        top: 2rem;
        left: 0rem;
    }
    .separation-sobre {
        width: 100%;
        height: 100%;
    }
    .title-sobre {
        padding: 2rem;
        height: 20%;
        background-color: #323232;
    }
    .title-sobre h2 {
        color: #fff;
    }
    .text-maps-sobre-letter span:nth-child(1) {
        padding-top: 1rem;
    }
}

@media (max-width: 665px) {
    .black-transparent-sobre p{
        font-size: 1.125rem;
    }
}

@media (max-width: 635px) {
    .all-certificate {
        grid-gap: 15px;
        grid-template-columns: 1fr 1fr;
    }
    .content-valores {
        padding: 3rem;
    }
}

@media (max-width: 560px) {
    .content-sobre {
        width: 80%;
        height: 80%;
    }
    .title-sobre {
        padding: 1rem;
    }
    .title-sobre h2 {
        font-size: 1.125rem;
    }
    .certificados h1 {
        font-size: 1.125rem;
    }
    #font-white {
        font-size: 1.125rem;
    }
    .text-maps-sobre-letter h4 {
        font-size: 1rem;
        padding-bottom: 0.5rem;
        font-weight: 400;
    }
    .text-maps-sobre-letter span {
        font-size: 0.875rem;
    }
    .background-initial-sobre {
        height: 65rem;
    }
    .title-sobre-black {
        padding: 1em;
    }
}

@media (max-width: 542px) {
    /* Parei por aqui */
    .background-initial-sobre {
        height: 70rem;
    }
}

@media (max-width: 532px) {
    /* Parei por aqui */
    .background-initial-sobre {
        height: 70rem;
    }
    .title-sobre {
        padding: 0.5rem;
        height: 10%;
    }
    .title-sobre-black {
        padding: 1.5em;
        height: 10%;
    }
    .text-maps-sobre-letter {
        height: 90%;
    }
    .text-maps-sobre {
        height: 90%;
    }
}

@media (max-width: 510px) {
    .content-valores {
        display: grid;
        grid-gap: 50px;
        grid-template-columns: 1fr;
        grid-auto-flow: row;
    }
}

@media (max-width: 455px) {
    .certificate img {
        width: 90%;
        height: 100%;
    }
    .certificate p {
        width: 90%;
    }
    .certificados {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 3rem;
    }
    .certificados h1 {
        padding-bottom: 3rem;
    }
    .valores {
        padding: 2rem 2rem 2rem 2rem;
    }
    .valores h2{
        font-size: 1.25rem;
        text-align: center;
    }
    .black-transparent-sobre p {
        padding: 3rem;
    }
}

@media (max-width: 375px) {
    .background-initial-sobre {
        height: 58rem;
    }
    .content-sobre {
        width: 90%;
    }
}

@media (max-width: 350px) {
    .all-certificate {
        grid-gap: 5px;
        grid-template-columns: 1fr;
    }
    .certificate img {
        width: 95%;
        height: 100%;
    }
    .certificate p {
        width: 95%;
    }
}

@media (max-width: 345px) {
    .black-transparent-sobre p {
        padding: 2rem;
    }
}

@media (max-width: 285px) {
    .background-initial-sobre {
        height: 60rem;
    }
}

/* Modal */

.modal-sobre{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    position: fixed;
    left: 0;
    top: 0;  
    animation: modal-sobre-animate 1s forwards ease-in-out;
}

@keyframes modal-sobre-animate {
    from{
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* parei aqui */
/*DEIXAR MODAL RESPONSIVO*/

.content-modal-sobre img{
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);  
    width: 100%;
    height: 100%;
    border-radius: 3px;
    box-shadow: 2px 2px 4px 4px rgba(0, 0, 0, 0.2);
    transition: all 0.5s ease-in-out;
    animation: img-certificate 0.4s forwards ease-in-out;
    animation-delay: 0.8s;
    visibility: hidden;
} 

@keyframes img-certificate {
    from {
        position: relative;
        left: 0rem;
        top: 5rem;
        opacity: 0.2;
        transform: scale(0.95);
    }
    to {
        position: relative;
        left: 0rem;
        top: 0rem;
        opacity: 1;
        transform: scale(1);
        visibility: visible;
    }
}

.disabled{
    display: none;
}

#close-modal{
    cursor: pointer;
    background-color: #323232;
    border-radius: 3px;
    animation: icon-certificate 0.4s forwards ease-in-out;
    box-shadow: 2px 2px 4px 4px rgba(0, 0, 0, 0.2);
    animation-delay: 1s;
    visibility: hidden;
    z-index: 10;
}

@keyframes icon-certificate {
    from {
        opacity: 0.2;
        transform: scale(0.95);
    }
    to {
        transform: scale(1);
        visibility: visible;
        opacity: 1;
    }
}

@media (max-width: 450px) {
    .content-modal-sobre{
        position: relative;
        left: 0rem;
        top: 3rem;
    }
    .content-modal-sobre img{
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);  
    } 
    .content-modal-sobre img{
        height: 90%;
    }
}