.background-initial {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 52rem;
    background-image: url("../../assets/images/background_pages/background-home.jpg");
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position-y: -8rem;
}

.black-transparent {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.65);
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
}

.black-transparent h2 {
    text-align: center;
    font-size: 1.5rem;
    font-weight: 200;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    color: #fff;
    padding-bottom: 0.5rem;
    z-index: 100000;
    font-family: 'Montserrat', sans-serif;
    animation: animate-h2 0.5s forwards ease-in-out;
    animation-delay: 0.5s;
    visibility: hidden;
}

@keyframes animate-h2 {
    from{
        position: relative;
        left: 0rem;
        top: 2rem;
        opacity: 0.2;
    } 
    to {
        position: relative;
        left: 0rem;
        top: 0rem;
        transform: translate(-0rem, -0rem);
        opacity: 1;
        visibility: visible;
    }
}

.black-transparent a {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    text-transform: uppercase;
    color: #fff;
    width: 15rem;
    height: 2.563rem;
    border-radius: 3px;
    padding: 1rem;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    background-color: #3f3f3fd8;
    transition: all 0.25s ease-in-out;
    animation: animate-link 0.5s forwards ease-in-out;
    font-family: porscha3;
    text-transform: uppercase;
    letter-spacing: 1px;
    animation-delay: 1s;
    visibility: hidden;
    z-index: 19999;
}

@keyframes animate-link {
    from{
        position: relative;
        left: 0rem;
        top: -1rem;
        opacity: 0.2;
    } 
    to {
        position: relative;
        left: 0rem;
        top: 0rem;
        opacity: 1;
        visibility: visible;
    }
    
}

.black-transparent a:hover {
    width: 17rem;
    background-color: #3f3f3fec;
}

.content-home {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding-top: 5rem;
    background-color: #FCFCFC;
}

.blindagem {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 62rem;
    height: 21rem;
    background-color: #fff;
    box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
}

.separate-form {
    width: 50%;
    height: 100%
}

.separate-form img {
    width: 100%;
    height: 100%;
    border-radius: 0px 3px 3px 0px;
    object-fit: cover;
}

.text-info {
    display: flex;
    flex-direction: column;
    padding: 2rem;
}

.text-info p {
    padding-bottom: 1.5rem;
    text-align: center;
    font-size: 1.125rem;
    font-family: porscha3;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #323232;
    text-transform: uppercase;
}

.text-info span {
    padding-bottom: 0.35rem;
    font-size: 1rem;
    font-weight: 300;
    color: #323232;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.15);
}

.beneficios {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding-top: 5rem;
    padding-bottom: 5rem;
}

.title p {
    font-size: 1.5rem;
    font-weight: 500;
    color: #323232;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
}

#icon-shadow{
    filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.25));
}

.icons-benefits {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 75%;
    padding-top: 2rem;
}

.icon-benefit {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.icon-benefit span {
    width: 20rem;
    text-align: center;
    padding-top: 0.5rem;
    font-size: 1.125rem;
    font-weight: 300;
    text-transform: uppercase;
    color: #323232;
}

.video-home {
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 2px 2px 5px 3px rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 32rem;
    background-image: url('../../assets/images/image-home2.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.video-play {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 75%;
    height: 70%;
}

.video-play video {
    width: 50rem;
    border: none;
    border-radius: 3px;
    box-shadow: 2px 2px 5px 5px rgba(0, 0, 0, 0.25);
}

.hidden-blind{
    visibility: hidden;
}

.show-blind{
    visibility: visible;
    animation: show-blind 0.75s forwards ease-in-out;
}

@keyframes show-blind {
    from{
        position: relative;
        left: -6rem;
        top: 0rem;
        transform: translate(5rem, -0rem);
        opacity: 0.2;
    }
    to{
        position: relative;
        left: 0rem;
        top: 0rem;
        transform: translate(-0rem, -0rem);
        opacity: 1;
    }
}

@media (max-width: 1295px) {
    .icons-benefits {
        width: 90%;
        padding-top: 2rem;
    }
}

@media (max-width: 1200px) {
    .background-initial {
        height: 38rem;
        background-position-y: -5rem;
    }
}

@media (max-width: 1050px) {
    .icon-benefit span {
        width: 15rem;
        font-size: 1rem;
    }
}

@media (max-width: 1000px) {
    .blindagem {
        width: 45rem;
        height: 25rem;
    }
}

@media (max-width: 815px) {
    .icons-benefits {
        flex-direction: column;
    }
    .icon-benefit {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
}

@media (max-width: 735px) {
    .blindagem {
        width: 100%;
        height: 26rem;
        background-color: #fff;
        box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.1);
        border-radius: 3px;
    }
}

@media (max-width: 677px) {
    .blindagem {
        height: 28rem;
    }
}

@media (max-width: 641px) {
    .blindagem {
        height: 35rem;
    }
    .video-play video {
        width: 30rem;
    }
}

@media (max-width: 545px) {
    .background-initial {
        height: 38rem;
        background-position-y: -5rem;
        background-position-x: -20rem;
    }
}

@media (max-width: 498px) {
    .video-play video {
        width: 25rem;
    }
    .blindagem {
        height: 35rem;
    }
    .separate-form:nth-child(1) {
        width: 60%;
        height: 100%
    }
    .separate-form:nth-child(2) {
        width: 40%;
        height: 100%
    }
    .background-initial {
        height: 45rem;
        background-position-y: -4rem;
        background-position-x: -20rem;
    }
}

@media (max-width: 447px) {
    .blindagem {
        flex-direction: column;
        height: 100%;
    }
    .separate-form:nth-child(1) {
        width: 100%;
        height: 100%;
    }
    .separate-form:nth-child(2) {
        width: 100%;
        height: 100%
    }
    .separate-form img {
        width: 100%;
        height: 100%;
        border-radius: 0px 0px 0px 0px;
        object-fit: cover;
    }
    .background-initial {
        height: 40rem;
        background-position-y: -4rem;
    }
    .video-home {
        height: 22rem;
    }
}

@media (max-width: 414px) {
    .video-play video {
        width: 20rem;
    }
    .background-initial {
        height: 38rem;
        background-position-y: -5rem;
        background-position-x: -20rem;
    }
    .black-transparent h2 {
        font-size: 1.25rem;
    }
    .black-transparent a {
        font-size: 1rem;
    }
    .title p {
        font-size: 1.25rem;
    }
    .icon-benefit span {
        font-size: 1rem;
    }
    .black-transparent a {
        width: 14rem;
        height: 2.563rem;
        border-radius: 3px;
        padding: 0.75rem;
    }
}

@media (max-width: 375px) {
    .background-initial {
        height: 45rem;
        background-position-y: -2rem;
        background-position-x: -20rem;
    }
}

@media (max-width: 365px) {
    .background-initial {
        height: 35rem;
        background-position-y: -2rem;
        background-position-x: -20rem;
    }
}

@media (max-width: 325px) {
    .video-play video {
        width: 18rem;
    }
    .video-home {
        height: 20rem;
    }
}

@media (max-width: 293px) {
    .video-play video {
        width: 17rem;
    }
    .video-home {
        height: 15rem;
    }
    .background-initial {
        height: 35rem;
        background-position-y: -2rem;
        background-position-x: -40rem;
    }
}