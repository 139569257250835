.content-menu{
    display: flex;
    align-items: center;
    padding: 1rem;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10000000;
}

.options{
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.options span{
    cursor: pointer;
}

#styled-option{
    text-decoration: none;
    color: #fff;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.150);
    font-size: 0.875rem;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
    transition: all 0.3s;
    padding: 1rem;
    animation: position-option 0.8s ease-in-out forwards;
}

.icon-option {
    padding: 1rem;
}

.icon-option span{
    cursor: pointer;
    text-decoration: none;
    color: #fff;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.150);
    font-size: 0.875rem;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
    transition: all 0.3s;
    margin-right: 0.25rem;
    animation: position-option 0.8s ease-in-out forwards;
}

@keyframes position-option {
    from {
        position: relative;
        left: 0rem;
        top: 1.05rem;
        opacity: 0.2;
    }
    to {
        position: relative;
        left: 0rem;
        top: 0rem;
        opacity: 1;
    }
}

#styled-option:hover,
.icon-option span:hover {
    color: #d3d3d3;
}

#pointer-icon{
    animation: position-option 0.8s ease-in-out forwards;   
    cursor: pointer;
}

.logo-area img{
    width: 8.75rem;
    height: 2.875rem;
    filter: drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.250));
    animation: position-image 0.8s ease-in-out forwards;
    cursor: pointer;
}
@keyframes position-image {
    from {
        position: relative;
        left: 0rem;
        top: 1.05rem;
        opacity: 0.2;
    }
    to {
        position: relative;
        left: 0rem;
        top: 0rem;
        opacity: 1;
    }
}

.logo-area{
    position: absolute;
    left: 50%;
    top: 0%;
    transform: translate(-50%, -0%);
    padding: 1rem;
}

.mobile-menu{
    display: none;
}

.disabled {
    display: none;
}

.servicos-dropdown{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.dropdown-options{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    left: 11.5rem;
    top: 3.5rem;
    background-color: #323232;
    border-radius: 4px;
    width: 10rem;
    text-align: center;
    animation: dropdown-options 0.5s forwards ease-in-out;
}

@keyframes dropdown-options {
    from {
        position: absolute;
        left: 11.5rem;
        top: 2rem;
        opacity: 0.2;
    }
    to{
        position: absolute;
        left: 11.5rem;
        top: 3.5rem;
        opacity: 1;
    }
}

.dropdown-options a{
    text-decoration: none;
    color: #fff;
    padding: 0.5rem;
    font-size: 0.875rem;
    transition: all 0.2s ease-in-out;
}

.dropdown-options a:hover{
    color: #bbbbbb;
}

.icon-option{
    display: flex;
    align-items: center;
    justify-content: center;
}

.disabled{
    display: none;
}

.modal-area-cliente {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.500);
    position: fixed;
    top: 50;
    height: 50;
    z-index: 100000000;
    animation: background-png 0.5s ease-in-out forwards;
    visibility: hidden;
}
@keyframes background-png{
    from {
        opacity: 0;
    }
    to{
        opacity: 1;
        visibility: visible;
    }
}

.content-modal-cliente{
    display: flex;
    flex-direction: column;
    text-align: center;
    background-color: #fff;
    border-radius: 3px;
    padding: 3rem;
    width: 30rem;
    animation: content-modal 0.5s ease-in-out forwards;
    animation-delay: 0.3s;
    visibility: hidden;
}

@keyframes content-modal{
    from {
        position: relative;
        top: 2rem;
        left: 0;
        opacity: 0;
    }
    to{
        position: relative;
        top: 0rem;
        left: 0;
        opacity: 1;
        visibility: visible;
    }
}

.content-modal-cliente h2{
    font-size: 1.375rem;
    font-family: porscha3bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.250);
    letter-spacing: 1px;
    padding-bottom: 0.5rem;
    animation: content-modal-h2 0.5s ease-in-out forwards;
    animation-delay: 0.5s;
    visibility: hidden;
}

@keyframes content-modal-h2{
    from {
        position: relative;
        top: 2rem;
        left: 0;
        opacity: 0;
    }
    to{
        position: relative;
        top: 0rem;
        left: 0;
        opacity: 1;
        visibility: visible;
    }
}

.input-cliente-modal{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 1rem;
    padding-bottom: 1.5rem;
}

.input-cliente-modal label{
    margin-right: 1rem;
    font-size: 1.125rem;
    font-weight: 300;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.250);
    padding-bottom: 0.25rem;
    animation: content-modal 0.5s ease-in-out forwards;
    animation-delay: 0.8s;
    visibility: hidden;
}

@keyframes content-modal{
    from {
        position: relative;
        top: 0m;
        left: -2rem;
        opacity: 0;
    }
    to{
        position: relative;
        top: 0rem;
        left: 0;
        opacity: 1;
        visibility: visible;
    }
}

.input-cliente-modal input[type="text"]{
    border: none;
    padding: 1.125rem;
    background-color: #f3f3f3;
    border-radius: 3px;
    width: 100%;
    box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.1);
    transition: all 0.5s ease-in-out;
    animation: content-modal-input 0.5s ease-in-out forwards;
    animation-delay: 1s;
    visibility: hidden;
}

@keyframes content-modal-input{
    from {
        position: relative;
        top: 2rem;
        left: 0;
        opacity: 0;
    }
    to{
        position: relative;
        top: 0rem;
        left: 0;
        opacity: 1;
        visibility: visible;
    }
}

.input-cliente-modal input[type="text"]:focus{
    outline: none;
    background-color: #fff;
}

.buttons-modal-cliente{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.buttons-modal-cliente span:nth-child(2){
    font-size: 1.125rem;
    font-weight: 300;
    padding: 0.5rem;
    background-color: #323232;
    color: #fff;
    cursor: pointer;
    width: 100%;
    border-radius: 2px;
    margin-right: 0.25rem;
    margin-left: 0.25rem;
    transition: all 0.25s ease-in-out;
    animation: content-modal 0.5s ease-in-out forwards;
    animation-delay: 1.6s;
    visibility: hidden;
}

.buttons-modal-cliente span:nth-child(1){
    font-size: 1.125rem;
    font-weight: 300;
    padding: 0.5rem;
    background-color: #2f1010;
    color: #fff;
    cursor: pointer;
    width: 100%;
    border-radius: 2px;
    margin-right: 0.25rem;
    margin-left: 0.25rem;
    transition: all 0.25s ease-in-out;
    animation: content-modal 0.5s ease-in-out forwards;
    animation-delay: 1.4s;
    visibility: hidden;
}

.content-modal-cliente span:hover{
    background-color: #000;
}

@keyframes content-modal{
    from {
        position: relative;
        top: 2rem;
        left: 0;
        opacity: 0;
    }
    to{
        position: relative;
        top: 0rem;
        left: 0;
        opacity: 1;
        visibility: visible;
    }
}


@media (max-width: 1100px) {
    .content-menu{
        display: none;
    }

    .mobile-menu{
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100000000;
    }

    .options-mobile-disable{
        display: none;
    }

    .logo-area-mobile img{
        width: 7.75rem;
        height: 2.55rem;
        filter: drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.250));
    }

    .column-mobile{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row-reverse;
        padding: 10px 30px;
        z-index: 1000000;
        background-color: #323232;
        width: 100%;
        height: 100%;
    }

    .options-mobile{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: #414141;
        width: 100%;
        height: 100%;
        z-index: 100000;
        transition: all 0.3s ease-out;
        animation: OptionsAnimate 1s ease-in-out forwards;
    }

    @keyframes OptionsAnimate {
        0% {
            position: relative;
            left: 0vh;
            top: -15vh;
            transform: translate(0vh, -15vh);
            opacity: 0;
        }
        100%{
            position: relative;
            left: 0vh;
            top: 0vh;
            transform: translate(-0vh, -0vh);
            opacity: 1;
        }
    }

    .options-mobile a{
        text-decoration: none;
        color: #fff;
        text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.150);
        font-size: 1rem;
        font-weight: 500;
        font-family: 'Montserrat', sans-serif;
        transition: all 0.3s;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
    }

    .options-mobile-dropdown{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: rgba(53, 53, 53, 0.95);
        padding: 1rem;
        width: 80%;
        height: 20%;
        z-index: 100000;
        border-radius: 3px;
        transition: all 0.3s ease-out;
        animation: OptionsAnimate 1s ease-in-out forwards;
    }

    .options-mobile-dropdown a{
        text-decoration: none;
        color: #fff;
        text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.150);
        font-size: 1rem;
        font-weight: 500;
        font-family: 'Montserrat', sans-serif;
        transition: all 0.3s;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        text-align: center;
    }

    #option-white{
        text-decoration: none;
        color: #fff;
        text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.150);
        font-size: 1rem;
        font-weight: 500;
        font-family: 'Montserrat', sans-serif;
        transition: all 0.3s;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        cursor: pointer;
    }

    .modal-dropdown{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 100000000;
    }
    #position-icon-menu{
        position: relative;
        left: 37%;
        top: 4.5%;
        transform: translate(-37%, -4.5%);
        cursor: pointer;
        z-index: 1000000;
        animation: animate-icon-drop 0.25s ease-in-out forwards;
        animation-delay: 1.2s;
        visibility: hidden;
    }

    @keyframes animate-icon-drop {
        from {
            position: relative;
            left: 38%;
            top: 5%;
            transform: translate(-38%, -5%);
            opacity: 0.2;
        }
        to {
            position: relative;
            left: 37%;
            top: 4.5%;
            transform: translate(-37%, -4.5%);
            opacity: 1;
            visibility: visible;
        }
    }
}

@media (max-width: 510px) {
    .content-modal-cliente{
        width: 95%;
        padding: 2rem;
    }
}

@media (max-width: 475px) {
    .logo-area-mobile img{
        width: 6rem;
        height: 2rem;
        filter: drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.250));
    }
}

@media (max-width: 360px) {
    .logo-area-mobile img{
        width: 5.5rem;
        height: 2rem;
    }
}
